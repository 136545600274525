import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import abductionIllustration from "../images/abduction-illustration.svg";
import { Link } from "gatsby";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Page non trouvée" />
      <div className="mx-auto">
        <img
          alt="Ghost getting abducted by aliens"
          className="block mx-auto w-1/2"
          src={abductionIllustration}
        />
        <h2 className="bg-red-100 text-2xl font-bold inline-block my-8 p-3">
          La page demandée n&apos;existe pas <Link to="/">cliquez ici pour retourner à l&apos;accueil</Link> ...
        </h2>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
